<template>
    <div class="inner_pages" id="checkout_clients">
        <sidebar-component v-model="showSidebar"/>
        <div class="container">
            <div class="respective_content">
                <div class="acton_header m-0">
                    <button type="button" class="bar_menu" @click="showSidebar = !showSidebar"><img src="@/assets/images/bar_menu.svg"></button>
                    <h2>Clients</h2>
                    <div class="search_area">
                        <input type="text" placeholder="Search..." @input="isTyping = true" v-model.trim="params.search"/>
                        <button class="search_btn">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                    <!-- <button type="button" class="video_btn" @click="tutorial = true;"><i class="fas fa-play"></i></button> -->
                </div>
                <div class="result_wpr new">
                    <div class="actions">
                        <ul class="left">
                            <li class="optionDrops contacts-tabs tutorial_btn px-0" @click="tutorialList = !tutorialList;" v-click-outside="closeTutorialListDropdown">
                                <label>Tutorials</label><img src="@/assets/images/play.svg">
                                <div class="dropdown_wpr" :class="tutorialList ? 'active' : ''">
                                    <ul>
                                        <li @click="tutorial = true;">How to use the clients feature</li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                        <ul class="right">
                            <!-- <li class="search_area ml-auto" :class="{'active': searchField}">
                                <input type="text" placeholder="Search..." @input="isTyping = true" v-model.trim="params.search"/>
                                <button class="search_btn" @click="searchField = !searchField;">
                                    <i class="fas fa-search"></i>
                                </button>
                            </li> -->
                            <li class="duration contacts-tabs" @click="handleDurationType()" v-click-outside="closeDateRangeDropdown">
                                {{ params.duration_title }}<span><i class="fas fa-angle-down"></i></span>
                                <div class="dropdown_wpr" :class="timeline ? 'active' : ''">
                                    <ul>
                                        <li v-for="(duration, d) of durations" :key="d" @click="handleDuration(duration)">{{duration}}</li>
                                    </ul>
                                </div>
                                <div class="custom_range" :class="{'active': isCustomRange }" @click.stop="event && event.stopPropagation ? event.stopPropagation() : ''">
                                    <h4>Custom Range</h4>
                                    <ul>
                                        <li>
                                            <label for="">Start Date</label>
                                            <datepicker v-model="params.start_date" :max-date="maxStartDate" auto-apply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                                        </li>
                                        <li>
                                            <label for="">End Date</label>
                                            <datepicker v-model="params.end_date" :min-date="minEndDate" auto-apply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                                        </li>
                                        <li>
                                            <button type="button" class="btn save_btn" @click="getClientByCustomDateRange()">Apply</button>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li class="optionDrops contacts-tabs" @click="taglist = !taglist" v-click-outside="closeTaglistDropdown">
                                {{ selectedTagName ? selectedTagName : 'Tags' }}<i class="fas fa-angle-down"></i>
                                <div class="dropdown_wpr" :class="taglist ? 'active' : ''">
                                    <ul>
                                        <li @click="handleTagFilter({name: '', tag_id: ''})" >All</li>
                                        <li v-for="(tag, t) in assignedTagList" :key="t" @click="handleTagFilter(tag)" >{{  tag.name }}</li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="contact_loader" v-if="clientLoader">
                        <quote-loader />
                    </div>
                    <div class="scroll_table">
                        <table class="show_header" v-show="!clientLoader">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th align="left">Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>LTV</th>
                                    <th>Recent Charge</th>
                                    <th>Created At</th>
                                </tr>
                            </thead>
                            <tbody v-if="clients.total">
                                <tr v-for="(client, c) in clients.data" :key="c">
                                    <td><a @click="handleOrderDetails(client)">{{ client.id }}</a></td>
                                    <td>
                                        <div class="user_wpr" @click="handleOrderDetails(client)">
                                            <h4>{{ client.full_name }}</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="email_wpr">
                                            <span class="email_add">{{ client.email ? client.email : '' }}</span>
                                        </div>
                                    </td>
                                    <td>{{ client.phone ? client.phone : '' }}</td>
                                    <td>{{ companyCurrencySymbol }}{{ client.life_time_value ? parseFloat(client.life_time_value).toFixed(2) : '0.00' }}</td>
                                    <td>{{ client.recent_charge ? moment(client.recent_charge).format('ll') : (client.abandoned_count ? 'Abandoned' : '-') }}</td>
                                    <td>{{ moment(client.created_at).format('ll | LT') }}</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="9" class="px-4 text-center" style="border-radius: 0 0 10px 10px;">No Records Found</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="table_footer">
                    <ul>
                        <li>{{ clients.from ? clients.from : 0 }} - {{ clients.to ? clients.to : 0 }} of {{ clients.total }}</li>
                    </ul>
                </div>
                <div class="pagination" v-show="clients.total">
                    <pagination v-model="params.page" :pages="clients.last_page" @update:modelValue="handlePagination" />
                </div>
            </div>
        </div>
        <profile-component v-model="details" :selected-client="selectedClient" />
        <video-tutorial v-model="tutorial" :tutorial-video="tutorialVideo"></video-tutorial>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    const VideoTutorial = defineAsyncComponent(() => import('@/components/VideoTutorial'))
    const ProfileComponent = defineAsyncComponent(() => import('@/pages/checkout/components/client/Profile'))
    const SidebarComponent = defineAsyncComponent(() => import('@/pages/checkout/components/Sidebar'))

    import moment from 'moment'
    import Helper from '@/utils/Helper'

    export default {
        name: 'Checkout Client',

        data () {
            return {
                timeline: false,
                taglist: false,
                durations: [
                    'Today',
                    'Yesterday',
                    'Last 7 Days',
                    'Last 14 Days',
                    'Last 30 Days',
                    'This Month',
                    'Last Month',
                    'This Year',
                    'All Time',
                    'Custom Range',
                ],
                start_date: '',
                end_date: '',
                maxStartDate: '',
                minEndDate: '',
                details: false,
                tutorialList: false,
                tutorial: false,
                tutorialVideo: '',
                isTyping: false,
                moment,
                params: {
                    per_page: 10,
                    page: 1,
                    search: '',
                    duration_title: 'Today',
                    start_date: moment.utc().format('YYYY-MM-DD'),
                    end_date: moment.utc().format('YYYY-MM-DD'),
                    tag_id: '',
                },
                isCustomRange: false,
                selectedClient: {},
                searchField: false,
                selectedTagName: '',
                showSidebar: false,
            }
        },

        watch:{
            'params.search' (val) {
                const vm = this;

                // vm.params.page = 1;
                vm.params.search = val;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.params.page = 1;
                            vm.getClients(vm.params);
                        }
                        if (vm.params.search.length == 0) {
                            vm.params.page = 1;
                            vm.getClients(vm.params);
                        }
                    }
                }
            },

            clientWatcher (val) {
                const vm = this;

                vm.getClients(vm.params);
            }
        },

        components: {
            ProfileComponent,
            VideoTutorial,
            SidebarComponent,
        },

        computed: mapState ({
            clients: state => state.checkoutModule.clients,
            clientLoader: state => state.checkoutModule.clientLoader,
            clientWatcher: state => state.checkoutModule.clientWatcher,
            assignedTagList: state => state.checkoutModule.assignedTagList,
            companyCurrencySymbol: state => state.checkoutModule.companyCurrencySymbol,
        }),

        mounted () {
            const vm = this;

            vm.handleDuration ('All Time');
            vm.getAssignedTagList();
        },

        methods: {
            ...mapActions({
                getClients: 'checkoutModule/getClients',
                getAssignedTagList: 'checkoutModule/getAssignedTagList',
            }),

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getClients(vm.params);
            },

            handleDuration (duration) {
                const vm = this;

                vm.params.duration_title = duration;

                if (duration == 'Custom Range') {
                    vm.isCustomRange = !vm.isCustomRange;
                } else {
                    const filter = Helper.filterDates(duration);

                    vm.params.start_date = filter.start_date;
                    vm.params.end_date   = filter.end_date;
                    vm.isCustomRange     = false;

                    vm.getClients(vm.params);
                }
            },

            getClientByCustomDateRange () {
                const vm = this;

                vm.isCustomRange     = false;
                vm.params.start_date =  moment(vm.params.start_date).isValid() ? moment(vm.params.start_date).format('YYYY-MM-DD') : '';
                vm.params.end_date   =  moment(vm.params.end_date).isValid() ? moment(vm.params.end_date).format('YYYY-MM-DD') : '';

                vm.getClients(vm.params);
            },

            handleOrderDetails (client) {
                const vm = this;

                vm.selectedClient = client;
                vm.details        = true;
            },

            closeDateRangeDropdown () {
                const vm = this;

                vm.timeline      = false;
                vm.isCustomRange = false;
            },

            closeTaglistDropdown () {
                const vm = this;

                vm.taglist = false;
            },

            closeTutorialListDropdown(){
                const vm = this;

                vm.tutorialList = false;
            },

            handleTagFilter (tag) {
                const vm = this;

                vm.selectedTagName = tag.name;
                vm.params.tag_id   = tag.tag_id;

                vm.getClients(vm.params);
            },

            handleDurationType () {
                const vm = this;

                if (!vm.timeline && vm.isCustomRange) {
                    vm.isCustomRange = false;
                }

                vm.timeline = !vm.timeline;
            }
        }
    }
</script>

<style scoped>
    .bar_menu {
        height: 18px;
        width: 18px;
        cursor: pointer;
        margin-right: 10px;
        margin-left: 0 !important;
        display: none;
    }
    .container{
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
    }
    .video_player .modal_body {
        width: 100%;
        height: 400px;
        display: flex;
        padding: 15px;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    .result_wpr.new{
        overflow: visible;
    }
    .result_wpr table td:first-child,
    .result_wpr table th:first-child {
        width: 90px;
        padding: 20px;
        text-align: left;
    }

    .result_wpr table td a {
        color: #2f7eed;
        font-weight: 500;
        cursor: pointer;
    }

    .actions>ul li.duration {
        position: relative;
        cursor: pointer;
    }
    .actions > ul li.duration .dropdown_wpr{
        left: auto;
        right: 1px;
    }

    .custom_range {
        text-align: left;
        background: #fff;
        position: absolute;
        top: 100%;
        right: 0;
        width: 200px;
        box-shadow: 0 1px 20px rgba(0, 0, 0, 0.15);
        border: 1px solid #E0E0E0;
        border-radius: 5px;
        margin-top: 1px;
        display: none;
        opacity: 0;
    }

    .custom_range.active {
        animation: dropMove 0.5s ease-in-out;
        animation-timing-function: cubic-bezier(1.0, 0.5);
        display: block;
        opacity: 1;
    }

    .custom_range:before {
        content: '';
        position: absolute;
        right: 17px;
        top: -6px;
        width: 10px;
        height: 10px;
        border-top: 1px solid #E0E0E0;
        border-left: 1px solid #E0E0E0;
        background: #fff;
        transform: rotate(45deg);
    }

    .custom_range h4 {
        padding: 10px 15px;
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        border-bottom: 1px solid #ececec;
    }

    .custom_range ul {
        display: flex;
        flex-direction: column;
        margin: 10px 0 15px;
        line-height: 0;
        gap: 12px;
    }

    .custom_range ul li {
        border: 0;
        padding: 5px 15px;
        height: auto;
        margin: 0;
        width: 100%;
        display: block;
    }

    .custom_range ul li label {
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        margin-bottom: 3px;
        display: block;
        justify-content: flex-start;
    }

    .custom_range ul li :deep(input) {
        width: 100%;
        border: 1px solid #cdcdcd;
        height: 30px;
        border-radius: 3px;
        padding: 0 5px 0 10px;
        color: #808080;
        font-size: 12px;
        line-height: 15px;
    }

    .custom_range .save_btn {
        padding: 7px 15px;
        background: #2f7eed;
        color: #fff;
        width: 100%;
        border-radius: 3px;
        cursor: pointer;
        font-size: 12px;
        line-height: 14px;
    }

    .result_wpr.new {
        border: 0;
    }

    .result_wpr.new .actions {
        border: 0;
        border-radius: 0;
        position: relative;
        z-index: 10;
        margin-bottom: 10px;
    }

    .result_wpr.new .actions>ul {
        align-items: center;
        gap: 15px;
    }

    .result_wpr.new .actions>ul li {
        flex: 0 0 auto;
        border: 0;
        background: transparent;
    }

    .result_wpr.new .actions>ul li.optionDrops {
        border-radius: 17px;
        padding: 7px;
        display: flex;
        justify-content: space-between;
    }

    .result_wpr.new .actions>ul li.search_area {
        padding: 0 31px 0 0;
        background: #fbfbfb;
        position: relative;
        height: 33px;
        margin-right: 10px;
        margin-left: auto;
    }

    .result_wpr.new .actions>ul li.search_area input[type=text] {
        font-size: 11px;
        height: 17px;
        width: 0;
        padding: 0;
        transition: all 0.3s ease-in-out;
    }

    .result_wpr.new .actions>ul li.search_area.active input[type=text] {
        padding: 0 15px;
        width: 450px;
    }

    .result_wpr.new .actions>ul li.search_area .search_btn {
        position: absolute;
        right: 0;
        top: 0;
        width: 31px;
        height: 31px;
        border-radius: 50%;
        font-size: 11px;
        color: #121525;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .result_wpr.new .actions>ul li.search_area .search_btn i {
        margin: 0;
    }

    .result_wpr.new .actions>ul li.contacts-tabs .dropdown_wpr {
        min-width: 160px;
    }

    .result_wpr.new .actions>ul li .checkbox i {
        margin-left: 0;
        color: #121525;
    }

    .result_wpr.new .actions>ul li .checkbox h5 {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #121525;
        padding-left: 12px;
    }

    .table_footer ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 20px 0 0;
    }

    .table_footer ul li {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #999;

    }

    .table_footer ul li.sort_list {
        padding: 7px 15px;
        border-radius: 20px;
        cursor: pointer;
        border: 1px solid #e9e9e9;
        background: #fff;
        color: #5a5a5a;
        position: relative;
        font-size: 11px;
        line-height: 14px;
    }

    .table_footer ul li.sort_list i {
        padding-left: 5px;
    }

    .table_footer ul li.sort_list :deep(.dropdown_wpr),
    .contacts-tabs :deep(.dropdown_wpr) {
        border-radius: 6px;
        overflow: hidden;
    }

    .table_footer ul li.sort_list :deep(.dropdown_wpr ul) {
        height: 80px;
        overflow: overlay;
    }

    .table_footer ul li.sort_list :deep(.dropdown_wpr ul::-webkit-scrollbar) {
        width: 3px;
    }

    .table_footer ul li.sort_list :deep(.dropdown_wpr ul::-webkit-scrollbar-thumb) {
        background: #dbdbdb;
    }

    .table_footer ul li.sort_list :deep(.dropdown_wpr ul li) {
        border: 0;
        padding: 7px 10px;
        font-size: 11px;
        line-height: 14px;
    }

    .table_footer ul li.sort_list :deep(.dropdown_wpr ul li.active) {
        background: #f5f5f5;
    }

    @media(max-width: 767px){
        .acton_header .search_area input{
            width: 170px;
        }
    }

    @media(max-width: 1199px){
        .bar_menu {
            display: block;
        }
    }
    @media(max-width: 600px){
        .acton_header{
            align-items: center;
        }
        .acton_header .search_area input{
            width: 200px;
            height: 30px;
        }
        .acton_header .search_area .search_btn{
            height: 30px;
            width: 30px;
        }
    }
</style>
